<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title>Frame Play Ground</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-form v-model="valid" ref="bannerGenerateForm" lazy-validation >
              <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="8">
                      <v-select
                        v-model="frameDropdown"
                        :items="frameList"
                        item-text="option"
                        item-value="value"
                        label="Select frame"
                        @change="loadFrameJson"
                        persistent-hint
                        return-object
                        single-line
                      ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-btn color="primary" @click="saveFrameJson()"> Save frame Json </v-btn>
                      </v-col>
                    </v-row>
                    <jsoneditor v-model="playgroundForm.jsonfile" :plus="false" height="400px" @error="jsonError">
                    </jsoneditor>
                    <v-text-field label="bannerid" v-model="playgroundForm.bannerid"></v-text-field>
                    <v-text-field label="Watermark " v-model="playgroundForm.watermark"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Name" v-model="playgroundForm.name"></v-text-field>
                    <v-text-field label="Address"  v-model="playgroundForm.address"></v-text-field>
                    <v-text-field label="phone"  v-model="playgroundForm.phone"></v-text-field>
                    <v-text-field label="email"  v-model="playgroundForm.email"></v-text-field>
                    <v-text-field label="website" v-model="playgroundForm.website"></v-text-field>
                    <v-text-field label="userlogo" v-model="playgroundForm.userlogo"></v-text-field>
                    <v-text-field label="Logo Width" v-model="playgroundForm.logoWidth"></v-text-field>
                    <v-text-field label="Logo Height" v-model="playgroundForm.logoHeight"></v-text-field>
                    <v-text-field label="Custom Banner Base64" v-model="playgroundForm.userBanner"></v-text-field>
                  </v-col>
              </v-row>
              <v-btn color="primary" @click="generateBanner()"> Preview </v-btn>
              <v-btn color="blue darken-1" text @click="clearForm()">
                Clear
              </v-btn>
            </v-form>
          </div>
          <v-divider></v-divider>
          <div class="pa-4" v-if="responseData.generatedFrames">
            <v-row>
              <v-col cols="6">
                <v-label>WhatsApp Status & Insta Stories (1080 x 1920)</v-label>
                <v-img
                  width="480"
                  :aspect-ratio="9/16"
                  :src="responseData.generatedFrames.b1080x1920"
                ></v-img>
                
              </v-col>
              <v-col cols="6">
                <v-label> Instagram & LinkedIn post (1200 x 1200 ) </v-label>
                <v-img
                  width="480"
                  :aspect-ratio="1/1"
                  :src="responseData.generatedFrames.b1200x1200"
                ></v-img>
                <v-label>Facebook & Twitter Post (1200 x 675)</v-label>
                <v-img
                  width="480"
                  :aspect-ratio="16/9"
                  :src="responseData.generatedFrames.b1200x675"
                ></v-img>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import Snackbar from '../../Snackbar.vue';
import Jsoneditor from 'v-jsoneditor/src/index'

export default {
  name: "FramePlayGround",
  components: {
    Snackbar,
    Jsoneditor
  },
  data: () => ({
    valid: true,
    playgroundForm:{
      name:'Patoliya Infotech',
      phone: '9904361666',
      email:'patoliyainfotech@mail.com',
      website:'https://patoliyainfotech.com', 
      address: '3008, Silver Business Point, 394105',
      userlogo:'',
      watermark: 0,
      bannerid:'6257c839b90c38239588c142',
      logoWidth: '',
      logoHeight: '',
      jsonfile: '',
      userBanner: '',
    },
    frameDropdown: '',
    frameList: [
      { option: 'Frame1', value: 'frame1'},
      { option: 'Frame2', value: 'frame2'},
      { option: 'Frame3', value: 'frame3'},
      { option: 'Frame4', value: 'frame4'},
      { option: 'Frame5', value: 'frame5'},
      { option: 'Frame6', value: 'frame6'},
      { option: 'Frame7', value: 'frame7'},
      { option: 'Frame8', value: 'frame8'},
      { option: 'Frame9', value: 'frame9'},
      { option: 'Frame10', value: 'frame10'},
      { option: 'Frame11', value: 'frame11'},
      { option: 'Frame12', value: 'frame12'},
      { option: 'Frame13', value: 'frame13'},
      { option: 'Frame14', value: 'frame14'},
      { option: 'Frame15', value: 'frame15'},
      { option: 'Frame16', value: 'frame16'},
      { option: 'Frame17', value: 'frame17'},
      { option: 'Frame18', value: 'frame18'},
      { option: 'Frame19', value: 'frame19'},
      { option: 'Frame20', value: 'frame20'},
      { option: 'Frame21', value: 'frame21'},
      { option: 'Frame22', value: 'frame22'},
      { option: 'Frame23', value: 'frame23'},
      { option: 'Frame24', value: 'frame24'},
      { option: 'Frame25', value: 'frame25'},
      { option: 'Frame26', value: 'frame26'},
      { option: 'Frame27', value: 'frame27'},
      { option: 'Frame28', value: 'frame28'},
      { option: 'Frame29', value: 'frame29'},
      { option: 'Frame30', value: 'frame30'},
      { option: 'Frame31', value: 'frame31'},
      { option: 'Frame32', value: 'frame32'},
      { option: 'Frame33', value: 'frame33'},
      { option: 'Frame34', value: 'frame34'},
      { option: 'Frame35', value: 'frame35'},
      { option: 'Frame36', value: 'frame36'},
      { option: 'Frame37', value: 'frame37'},
      { option: 'Frame38', value: 'frame38'},
      { option: 'Frame39', value: 'frame39'},
      { option: 'Frame40', value: 'frame40'},
      { option: 'Frame41', value: 'frame41'},
      { option: 'Frame42', value: 'frame42'},
      { option: 'Frame43', value: 'frame43'},
      { option: 'Frame44', value: 'frame44'},
      { option: 'Frame45', value: 'frame45'},
      { option: 'Frame46', value: 'frame46'},
      { option: 'Frame47', value: 'frame47'},
      { option: 'Frame48', value: 'frame48'},
      { option: 'Frame49', value: 'frame49'},
      { option: 'Frame50', value: 'frame50'},
      { option: 'Frame51', value: 'frame51'},
      { option: 'Frame52', value: 'frame52'},
      { option: 'Frame53', value: 'frame53'},
      { option: 'Frame54', value: 'frame54'},
      { option: 'Frame55', value: 'frame55'},
      { option: 'Frame56', value: 'frame56'},
      { option: 'Frame57', value: 'frame57'},
      { option: 'Frame58', value: 'frame58'},
      { option: 'Frame59', value: 'frame59'},
      { option: 'Frame60', value: 'frame60'}
    ],
    responseData: {}
  }),
  watch:{},
  computed: {},
  mounted: function () {},
  created: function () {},
  methods: {
    jsonError() {
      console.log("Json have a error");
    }
    ,
    saveFrameJson() {
      let formData = new FormData();
      formData.append('jsonfile', JSON.stringify(this.playgroundForm.jsonfile));
      formData.append('framenumber', this.frameDropdown.value);
      axios.post(this.Node_JS_HOST + "/api/v1/web/banner-master/save-frame-json",formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res) {
              
              this.snackbarText = res.data.message;
              this.isShowSnackbar = true;
            }
          })
    },
    loadFrameJson(selectObj) {
      this.playgroundForm.frame= selectObj.value;
      let formData = new FormData();
      formData.append('frame', selectObj.value);
      axios.post(this.Node_JS_HOST + "/api/v1/web/banner-master/get-frame-json",formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res) {
              
              this.snackbarText = "Json file successfully fetched";
              this.isShowSnackbar = true;
              this.playgroundForm.jsonfile = res.data;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Something gone wrong";
            this.isShowSnackbar = true;
          });
    },
    clearForm() {
      this.playgroundForm.name = "";
      this.playgroundForm.phone = "";
      this.playgroundForm.email = "";
      this.playgroundForm.website = "";
      this.playgroundForm.frame = "";
      this.playgroundForm.address = "";
      this.playgroundForm.userlogo = "";
      this.playgroundForm.bannerid = "";
      this.playgroundForm.jsonfile = "";
      this.playgroundForm.logoHeight = "";
      this.playgroundForm.logoWidth = "";
      this.playgroundForm.userBanner = "";
      this.frameDropdown = '';
      
    },
    generateBanner() {
      if (this.$refs.bannerGenerateForm.validate()) {
        let formData = new FormData();
        formData.append('name', this.playgroundForm.name);
        formData.append('phone', this.playgroundForm.phone);
        formData.append('email', this.playgroundForm.email);
        formData.append('website', this.playgroundForm.website);
        formData.append('frame', this.frameDropdown.value);
        formData.append('address', this.playgroundForm.address);
        formData.append('userlogo', this.playgroundForm.userlogo);
        formData.append('watermark', this.playgroundForm.watermark);
        formData.append('bannerid', this.playgroundForm.bannerid);
        formData.append('jsonfile', JSON.stringify(this.playgroundForm.jsonfile));
        formData.append('logoWidth', this.playgroundForm.logoWidth);
        formData.append('logoHeight', this.playgroundForm.logoHeight);
        formData.append('userBanner', this.playgroundForm.userBanner);
        
        let endPoint = this.Node_JS_HOST + "/api/v1/web/banner-master/v2/generate-preview"
        if ( this.playgroundForm.userBanner != '' ) {
          endPoint = this.Node_JS_HOST + "/api/v1/web/banner-master/generate-custom-banner"
        }
        axios.post(endPoint,formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res) {
              
              this.snackbarText = "Banners preview generated successfully";
              if (res.data == "data not match") {
                this.snackbarText = "Invalid JSON";
              }
              this.isShowSnackbar = true;
              this.responseData = res.data;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
  .w-100{
    max-width: 100px;
  }
  .w-unset{
    max-width: unset;
  }
</style>